<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalState"
      :class="modalId == 'addNewModule' ? 'show' : ''"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle1"
      style="display: block"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal_ac_head"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalCenterTitle">New Module</h5>
            <a
              class="close"
              @click="$store.dispatch('modalClose', 'addNewModule')"
            >
              <span aria-hidden="true">×</span>
            </a>
          </div>
          <form
            @submit.prevent="moduleCreate"
            method="post"
            class="bg-theam-secondary pt-4"
            enctype="multipart/form-data"
          >
            <div class="modal-body row">
              <div class="col-md-7">
                <div class="group">
                  <div class="group-caption">Module Information</div>
                  <div class="row mb-2">
                    <div class="col-md-4">
                      Module Type<i class="error">*</i>
                    </div>
                    <div class="col-md-8">
                      <select
                        class="form-control"
                        v-model="formData.module_type"
                        required
                      >
                        <option value="parent">Parent</option>
                        <option value="child">Child</option>
                      </select>
                    </div>
                  </div>
                  <div class="row mb-2" v-if="formData.module_type == 'child'">
                    <div class="col-md-4">
                      Parent Module<i class="error">*</i>
                    </div>
                    <div class="col-md-8">
                      <select class="form-control" v-model="formData.parent_id">
                        <option value="" selected>Select Parent Module</option>
                        <option
                          v-for="(md, index) in dataLists1"
                          :key="index"
                          :value="md.id"
                        >
                          {{ md.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="row mb-2" v-else>
                    <div class="col-md-4">
                      Module Group<i class="error">*</i>
                    </div>
                    <div class="col-md-8">
                      <select
                        class="form-control"
                        v-model="formData.module_group"
                      >
                        <option value="" selected>Select Module Group</option>
                        <option
                          v-for="(group, index) in dataLists3"
                          :key="index"
                          :value="group.id"
                        >
                          {{ group.title }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-md-4">
                      Module Name<i class="error">*</i>
                    </div>
                    <div class="col-md-8">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Module Name"
                        v-model="formData.name"
                        required
                      />
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-md-4">Module Key<i class="error">*</i></div>
                    <div class="col-md-8">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Module Key"
                        v-model="formData.module_key"
                        required
                      />
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-md-4">
                      Module Rank<i class="error">*</i>
                    </div>
                    <div class="col-md-8">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Module Rank"
                        v-model="formData.module_rank"
                        required
                      />
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-md-4">
                      Module Url/Path<i class="error">*</i>
                    </div>
                    <div class="col-md-8">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Module Url/Path"
                        v-model="formData.module_url"
                        required
                      />
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-md-4">Module Icon</div>
                    <div class="col-md-8">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="fa fa-home"
                        v-model="formData.module_icon"
                      />
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-md-4">
                      Display Sidebar<i class="error">*</i>
                    </div>
                    <div class="col-md-8">
                      <select
                        class="form-control"
                        v-model="formData.view_sidebar"
                      >
                        <option value="1">Show</option>
                        <option value="0">Hide</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-5">
                <div class="group">
                  <div class="group-caption">Role Details</div>
                  <div
                    class="row mb-2"
                    v-for="(role, index) in dataLists2"
                    :key="index"
                  >
                    <div class="col-md-2">
                      <input
                        type="checkbox"
                        :value="role.id"
                        v-model="formData.roles"
                      />
                    </div>
                    <div class="col-md-10">
                      {{ role.name }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-right mt-3">
                <button class="btn btn-primary">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      formData: {
        id: null,
        module_type: "child",
        module_group: "",
        parent_id: "",
        name: "",
        module_key: "",
        module_url: "",
        module_icon: "",
        module_rank: "",
        view_sidebar: "1",
        roles: [],
      },
    };
  },
  computed: {
    ...mapGetters([
      "processing",
      "eventMessage",
      "dataLists",
      "dataLists1",
      "dataLists2",
      "dataLists3",
      "modalId",
      "dataId",
      "modalState",
      "modalMode",
    ]),
  },
  mounted() {
    this.$store.commit("getData2", "api/roles");
    this.$store.commit("getData3", "api/module/groups");
  },
  methods: {
    moduleCreate() {
      if (this.modalMode == "create") {
        this.$store.commit("setApiUrl", "api/modules/store");
        this.$store.commit("addData", this.formData);
      } else if (this.modalMode == "edit") {
        this.$store.commit("setApiUrl", `api/modules/${this.formData.id}`);
        this.$store.commit("updateData", this.formData);
      }
    },
    clearData() {
      this.formData.id = null;
      this.formData.module_type = "child";
      this.formData.module_group = "";
      this.formData.parent_id = "";
      this.formData.name = "";
      this.formData.module_key = "";
      this.formData.module_url = "";
      this.formData.module_icon = "";
      this.formData.module_rank = "";
      this.formData.view_sidebar = "1";
      this.formData.roles = [];
    },
  },
  watch: {
    "formData.module_type"(value) {
      if (value == "parent") {
        this.formData.parent_id = "";
      } else if (value == "child") {
        this.formData.module_group = "";
      }
    },
    dataId(value) {
      let details = this.dataLists.find(function (data) {
        return data.id == value;
      });
      if (details.roles.length > 0) {
        let roles = [];
        details.roles.forEach((role) => {
          roles.push(role.id);
        });
        const unique = (value, index, self) => {
          return self.indexOf(value) === index;
        };
        details.roles = roles.filter(unique);
        this.formData = details;
      } else {
        this.formData = details;
      }
    },
    modalMode(value) {
      if (value) {
        this.$store.commit("getData1", "api/modules?module_type=parent");
      }
    },
  },
};
</script>