<template>
  <div class="content-body">
    <div class="row mg-0">
      <div class="col-sm-5">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">Configuration</a>
                </li>
                <li class="breadcrumb-item text-capitalize active" aria-current="page">Modules</li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Module Management</h4>
          </div>
        </div>
      </div>
      <div class="col-sm-0 tx-right col-lg-7">
        <button
                type="button"
                class="btn btn-sm btn-primary mg-t-10 mg-r-0 mg-b-10"
                @click="
                $store.dispatch('modalWithMode', {
                  id: 'addNewModule',
                  mode: 'create',
                })
              "
        >
          New Module
        </button>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head">
      <div class="row">
        <div class="filter-input col-md-9 d-flex mr-0 pr-0">
          <select v-model="filter.size">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
          </select>
        </div>
        <div class="col-md-3 ml-0 pl-0">
          <input type="text" v-model="filter.key"  class="float-right" placeholder="Search...">
        </div>
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12">
        <table class="table table2 table-hover" id="tableData">
          <thead>
          <tr>
            <th scope="col">S.N</th>
            <th scope="col">Name</th>
            <th scope="col">Key</th>
            <th scope="col">Type</th>
            <th scope="col">Path</th>
            <th scope="col">Rank</th>
            <th scope="col">Display Sidebar</th>
            <th scope="col" class="text-center">Action</th>
          </tr>
          </thead>
          <tbody v-for="(md,index) in dataSets" :key="index">
          <tr v-if="!removeList.includes(md.id)">
            <th scope="row">{{ ++index }}</th>
            <td>{{ md.name }}</td>
            <td>{{ md.module_key }}</td>
            <td>
                <span class="badge badge-pill badge-primary text-capitalize">{{
                  md.module_type
                }}</span>
            </td>

            <td>/{{ md.module_url }}</td>
            <td>{{ md.module_rank }}</td>
            <td>
                <span
                        class="badge badge-pill badge-danger"
                        v-if="md.view_sidebar === 0"
                >Hide</span
                >
              <span
                      class="badge badge-pill badge-success"
                      v-if="md.view_sidebar === 1"
              >Show</span
              >
            </td>
            <td class="text-center">
              <a
                      href="javascript:;"
                      @click="edit('addNewModule', md.id)"
                      class="mr-3"
              >
                <i class="fa fa-edit"></i>
              </a>
              <a href="javascript:;" @click="drop(md.id)">
                <i class="fa fa-trash"></i>
              </a>
            </td>
          </tr>
          </tbody>
        </table>
        <Pagination />
        <moduleCreate />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import {Search} from "../../../../mixins/search";
  import moduleCreate from "./create";
  import Pagination from "../../components/pagination/pagination";

  export default {
    mixins: [Search],
    components: { moduleCreate, Pagination},
    methods: {
      edit(modal, id) {
        this.$store.commit("setDataId", id);
        this.$store.dispatch("modalWithMode", { id: modal, mode: "edit" });
      },
      drop(id) {
        this.$store.commit("setDataId", id);
        this.$store.commit('dropRecord','api/modules/'+id);
      },getData(){
        this.$store.commit("getData", `api/modules/size/${this.filter.size}`);
      }
    },
    computed: {
      ...mapGetters([
        "icons",
        "dataId",
        "dataLists",
        "removeList",
        "pagination",
        "eventMessage",
      ]),
    },
    mounted() {
      this.getData();
    },
    watch: {
      eventMessage(value) {
        if (value.indexOf("deleted success") >= 0) {
          this.$store.commit("setRemoveList", this.dataId);
        }
      },
    },
    destroyed() {
      this.$store.dispatch("destroyEvent");
    },
  };
</script>